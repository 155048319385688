/* eslint-disable react/no-unescaped-entities */
import { Text, Center, Box } from '@chakra-ui/react';
import Image from 'next/image';
import Link from 'next/link';

interface Props {
	img: string;
	link?: string;
	primaryText: string;
	secondaryText: string;
	ctaLabel?: string;
	withHeader?: boolean;
	handleClick?: () => void;
}

interface CTAProps {
	label?: string;
	link?: string;
	handleClick?: () => void;
}

const CTA = ({ link, handleClick, label }: CTAProps) => (
	<Box
		as={link ? 'a' : 'button'}
		cursor="pointer"
		fontWeight="medium"
		py="2"
		px="8"
		rounded="md"
		border="1px"
		fontSize="sm"
		onClick={() => handleClick?.()}
		borderColor="charcoal.800"
		color="charcoal.800"
		transition="all .2s"
		_hover={{
			bg: 'charcoal.800',
			color: 'clay.100',
			borderColor: 'transparent',
		}}
	>
		{label}
	</Box>
);

export default function EmptyState({
	img,
	link,
	ctaLabel,
	handleClick,
	primaryText,
	withHeader,
	secondaryText,
}: Props): JSX.Element {
	return (
		<Center
			h={{
				base: withHeader ? 'calc(100vh - 191.86px)' : '100vh',
				md: withHeader ? 'calc(100vh - 137.59px)' : '100vh',
			}}
			w="100%"
			px="12"
			flexDir="column"
		>
			<Box pos="relative" minW="300px" minH={{ base: '200px', md: '250px' }}>
				<Image layout="fill" src={img} alt="404 pic" objectFit="contain" />
			</Box>
			<Text
				mt="12"
				fontSize={{ base: 'xl', md: '4xl' }}
				color="charcoal.800"
				textAlign="center"
				fontWeight="bold"
			>
				{primaryText}
			</Text>
			<Text
				mt="0"
				fontSize={{ base: 'lg', md: '2xl' }}
				color="charcoal.800"
				textAlign="center"
			>
				{secondaryText}
			</Text>
			<Center mt="9">
				{link ? (
					<Link href={link} passHref>
						<Box
							as={link ? 'a' : 'button'}
							cursor="pointer"
							fontWeight="medium"
							py="2"
							px="8"
							rounded="md"
							border="1px"
							fontSize="sm"
							borderColor="charcoal.800"
							color="charcoal.800"
							transition="all .2s"
							_hover={{
								bg: 'charcoal.800',
								color: 'clay.100',
								borderColor: 'transparent',
							}}
						>
							{ctaLabel}
						</Box>
					</Link>
				) : !ctaLabel ? null : (
					<CTA label={ctaLabel} handleClick={handleClick} link={link} />
				)}
			</Center>
		</Center>
	);
}

/* eslint-disable react/no-unescaped-entities */
import EmptyState from '@/components/molecules/EmptyState';
import Head from 'next/head';

export default function FourOFour(): JSX.Element {
	return (
		<>
			<Head>
				<title>Page Not Found</title>
			</Head>
			<EmptyState
				img="/404.png"
				primaryText="Uh oh!"
				secondaryText="We can't find this page."
				ctaLabel="EXPLORE DESTINATIONS"
				link="/"
			/>
		</>
	);
}
